*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

:root {
  --color-brand: #007AFF;
  --color-bg: #F8FBFE;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background-color: var(--color-bg);
  color: #000;
}

table {
  width: 100%;
}

th {
  text-align: left;
}

:focus {
  outline: 0;
}

h1 {
  font-size: 32px;
}

.hc-wrap-select .hc-select {
  background-color: #F2F3F8;
  border-radius: 5px;
  border-color: #F2F3F8;
  font-size: 14px;
  padding: 10px 15px;
  appearance: none;
}

.hc-wrap-select {
  position: relative;
}

.hc-wrap-select .hc-icon.hc-icon--arrows {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #F2F3F8;
}

.hc-link {
  color: #0000d0;
}

.hc-link--pseudo {
  color: #666;
  font-size: 13px;
}

/*  inputs  */
.hc-input {
  padding: .5rem .8rem;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 50px;
  margin-bottom: 1rem;
  font-size: inherit;
  background-color: inherit;
}

hx-date-select .form-control {
  height: inherit;
  background: #F2F3F8;
  border-radius: 5px;
  padding: 10px 15px;
  border-color: #F2F3F8;
}

hx-date-select .form-control:focus {
  /*height: inherit;*/
  background: #F2F3F8;
  /*border-radius: 5px;*/
  /*padding: 10px 15px;*/
  border-color: #F2F3F8;
  box-shadow: none;
}

hx-date-select .input-group-append>.btn {
  background-color: #f2f3f8;
  border: 0;
}

hx-date-select .fill-current {
  fill: #0987ff;
  height: 16px !important;
  width: 16px !important;
}

/* Buttons */
.hc-button {
  display: inline-block;
  color: white;
  border: none;
  cursor: pointer;
  /* padding: .35rem .8rem; */
  padding: .6rem 1.2rem;
  border-radius: 4px;
  line-height: 1.45;
  font-size: 15px;
  user-select: none;
}

.hc-button--primary {
  background: #9816f4;
}

.hc-button--success {
  background: #00C67F;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.hc-button--danger {
  background: #f4516c;
}

.hc-button--secondary {
  background-color: #CCFFE0;
  border: 2px solid #2FCC71;
  color: #2FCC71;
  font-weight: 700;
}

.hc-button--action {
  background-color: #0987ff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.hc-button:hover {
  box-shadow: none;
}

.hc-button--l {
  padding: .8rem 2rem;
}

.hc-button--link {
  color: #0000d0;
  background-color: transparent;
  border-color: transparent;
}

.hc-button + .hc-button {
  margin-left: 10px;
}

/* pagination */
.hc-pagination {
  padding: 20px 0 10px;
}

/* flash */
.hc-flash {
  background-color: #e9ecef;
  padding: 10px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Table */
.hc-table {
  width: 100%;
  border-collapse: collapse;
}

.hc-table--font_s {
  font-size: 14px;
}

.hc-table__cell {
  padding: 10px 5px;
}

.hc-table--striped .hc-table__body .hc-table__row:nth-of-type(odd) {
  background-color: #f4f5f8;
}

.hc-table--pure .hc-table__row {
  border-bottom: 1px solid #E5E5E5;
}

.hc-table--pure .hc-table__cell {
  font-size: 14px;
  padding: 7px 5px;
}

.hc-table--pure .hc-table__cell:first-child,
.hc-table--pure .hc-table__cell:last-child {
  padding-left: 20px;
}

.hc-table--pure .hc-table__head {
  color: #666;
  font-size: 12px;
}

.hc-table--brand {
  font-size: 14px;
}

.hc-table--brand .hc-table__head .hc-table__cell {
  font-weight: 300;
}

.hc-table--brand .hc-table__row {
  border-bottom: 1px solid #E0E0E0;
}

.hc-table--brand .hc-table__cell:first-child {
  padding-left: 30px;
}

.hc-table__number {
  font-weight: bold;
  font-size: 22px;
  line-height: normal;
  color: #0788FF;
  text-decoration: none;
}

/* Fake table */
.hc-ftable {
  width: 100%;
  font-size: 14px;
}

.hc-ftable__header {
  display: flex;
  justify-content: space-between;
}

.hc-ftable__body {
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #333;
}

.hc-ftable__row {
  display: flex;
  justify-content: space-between;
}

.hc-ftable__cell:first-child {
  width: 50%;
  border-left: 0;
}

.hc-ftable__cell {
  width: 25%;
  padding: 5px 7px;
  border-left: 1px solid #DDDDDD;
}

.hc-ftable__header .hc-ftable__cell {
  font-weight: 600;
  padding-bottom: 10px;
  border-left-color: transparent;
}

/* badges */
.hc-badge {
  display: inline-block;
  padding: 1px 7px 3px 7px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #a3a3a3;
  border-radius: 3px;
  font-size: 12px;
  letter-spacing: .3px;
  width: 5rem;
}

.hc-badge--info {
  background-color: #36a3f7;
}

.hc-badge--success {
  background-color: #2cbe4e;
}

.hc-badge--brand {
  background-color: #716aca;
}

/* content */
.hc-subheader {
  padding: 2rem 3rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 940px;
  width: 100%;
}

.hc-subheader__row {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
}

.hc-subheader__title {
  margin-right: 0.3rem;
}

.hc-content {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  overflow: scroll;
  max-height: 620px;
}

@media (max-width: 768px) {
  .hc-content {
    padding: 1rem 1rem 0;
  }
}

.hc-content__container {
  width: 100%;
  height: 100vh;
  max-height: 600px;
  background: #FFFFFF;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: scroll;
  padding: 0.3rem;
}

@media (min-width: 1279px) {
  .hc-content__container {
    max-height: 100%;
  }
}

.hc-content__item {
  padding: 1rem;
  max-height: inherit;
}

.hc-content__scrollable {
  height: calc(100% - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  width: calc(100% + 10px);
}

.hc-content__scrollable--full {
  height: 100%;
}

.hc-subheader__back {
  margin-top: 10px;
  display: inline-block;
}
.hc-subheader__time {
  font-size: 24px;
  font-weight: 300;
}

/* Others */
.text-right {
  /* deprecated */
  text-align: right;
}

.is--align-right {
  text-align: right;
}

.is--align-center {
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* pagination */
.hc-subheader .ngx-pagination {
  margin: 0;
}

/* hc-icon */
.hc-icons {
  position: relative;
  margin: 0 3px;
  display: inline-block;
  vertical-align: middle;
}

.hc-icons--asc,
.hc-icons--desc {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #666 transparent;
}

.hc-icons--desc {
  border-width: 5px 5px 0 5px;
  border-color: #666 transparent transparent transparent;
}

/* modal */
/*
sm: 544px,
md: 768px,
lg: 992px,
xl: 1200px
*/
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

.modal-close {
  background: transparent;
  border-color: transparent;
  font-size: 1.1rem;
  font-weight: 600;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden;
  padding-right: 17px; /* pseudo scroll */
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.modal-xl {
  max-width: 90%;
}

    .modal .modal-content {
  border: 0;
  border-radius: 0;
  border-radius: 4px; }
  .modal .modal-content .modal-header {
    padding: 25px; }
    .modal .modal-content .modal-header .modal-title {
      font-weight: 400;
      font-size: 1.2rem; }
    .modal .modal-content .modal-header .close {
      line-height: 0;
      font-family: "LineAwesome";
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      font-size: 0;
      outline: none !important; }
      .modal .modal-content .modal-header .close:before {
        content: ""; }
      .modal .modal-content .modal-header .close:before {
        font-size: 1.2rem; }
      .modal .modal-content .modal-header .close span {
        display: none; }
  .modal .modal-content .modal-body {
    padding: 2rem 2.2rem; }
  .modal .modal-content .modal-footer {
    padding: 2rem 2.2rem; }

.modal .modal-content {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  -moz-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2); }
  .modal .modal-content .modal-header .modal-title {
    color: #3f4047; }
  .modal .modal-content .modal-header .close span {
    color: #575962; }











    /*!
 * FullCalendar v3.6.2 Stylesheet
 * Docs & License: https://fullcalendar.io/
 * (c) 2017 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left; }

.fc-rtl {
  text-align: right; }

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3; }

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3; }

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7; }

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer; }

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0; }

.fc-state-default {
  /* non-theme */
  border: 1px solid; }

.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle; }

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6; }

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear; }

.fc-state-down,
.fc-state-active {
  background-color: #cccccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none; }

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block; }

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px; }

.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px; }

.fc-popover .fc-header .fc-title {
  margin: 0 2px; }

.fc-popover .fc-header .fc-close {
  cursor: pointer; }

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right; }

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px; }

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0; }

.fc-clear {
  clear: both; }

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */ }

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */ }

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  box-sizing: border-box;
  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */ }

.fc th {
  text-align: center; }

.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */ }

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
  cursor: pointer; }

a[data-goto]:hover {
  text-decoration: underline; }

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0; }

.fc-row table {
  /* don't put left/right border on anything within a fake row.
	   the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent; }

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */ }

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative; }

.fc-row .fc-bg {
  z-index: 1; }

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent; }

.fc-row .fc-bgevent-skeleton {
  z-index: 2; }

.fc-row .fc-highlight-skeleton {
  z-index: 3; }

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */ }

.fc-row .fc-helper-skeleton {
  z-index: 5; }

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  /* extra precedence to prevent theme-provided backgrounds */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* don't put a border between events and/or the day number */
  border-bottom: 0; }

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0; }

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  -webkit-overflow-scrolling: touch; }

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  /* default BORDER color */ }

.fc-event,
.fc-event-dot {
  background-color: #3a87ad;
  /* default BACKGROUND color */ }

.fc-event,
.fc-event:hover {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */ }

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */ }

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed; }

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: .25; }

.fc-event .fc-content {
  position: relative;
  z-index: 2; }

/* resizer (cursor AND touch devices) */
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4; }

/* resizer (touch devices) */
.fc-event .fc-resizer {
  display: none; }

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block; }

/* hit area */
.fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px; }

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3); }

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0; }

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */ }

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */ }

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */ }

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px; }

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */ }

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */ }

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px; }

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
  /* a little bit more space before the first event */ }

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: .25; }

.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden; }

.fc-day-grid-event .fc-time {
  font-weight: bold; }

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */ }

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */ }

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none; }

a.fc-more:hover {
  text-decoration: underline; }

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none; }

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */ }

.fc-more-popover {
  z-index: 2;
  width: 220px; }

.fc-more-popover .fc-event-container {
  padding: 10px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
  position: absolute;
  border: 0 solid red; }

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

/*
TODO: more distinction between this file and common.css
*/
/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #ddd; }

.fc-unthemed .fc-popover {
  background-color: #fff; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666; }

.fc-unthemed td.fc-today {
  background: #fcf8e3; }

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3; }

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative; }

.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%; }

.fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%; }

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%; }

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%; }

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%; }

.fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px; }

/* List View
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-disabled-day {
  background-image: none; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-event {
  /* overpower jqui's styles on <a> tags. TODO: more DRY */
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
  /* undo ui-widget-header bold */
  font-weight: normal; }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome bold */ }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
  /* see through to fc-bg */ }

.fc.fc-bootstrap3 a {
  text-decoration: none; }

.fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline; }

.fc-bootstrap3 hr.fc-divider {
  border-color: inherit; }

.fc-bootstrap3 .fc-today.alert {
  border-radius: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-popover .panel-body {
  padding: 0;
  /* undo built-in padding */ }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none; }

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  text-align: center; }

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em; }

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em; }

.fc-toolbar .fc-left {
  float: left; }

.fc-toolbar .fc-right {
  float: right; }

.fc-toolbar .fc-center {
  display: inline-block; }

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em; }

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0; }

/* title text */
.fc-toolbar h2 {
  margin: 0; }

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative; }

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2; }

.fc-toolbar .fc-state-down {
  z-index: 3; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4; }

.fc-toolbar button:focus {
  z-index: 5; }

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.fc-view,
.fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1; }

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */ }

.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */ }

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden; }

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

/* week and day number styling */
.fc-day-top.fc-other-month {
  opacity: 0.3; }

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 2px; }

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px;
  /* column headers can't have as much v space */ }

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right; }

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left; }

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0; }

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px; }

.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080; }

/* when week/day number have own column */
.fc-basic-view td.fc-week-number {
  text-align: center; }

.fc-basic-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em; }

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */ }

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */ }

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */ }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1; }

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */ }

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent; }

.fc-time-grid > .fc-bg {
  z-index: 1; }

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */ }

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0; }

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1; }

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3; }

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4; }

.fc-time-grid .fc-now-indicator-line {
  z-index: 5; }

.fc-time-grid .fc-helper-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */ }

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted; }

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */ }

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */ }

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px; }

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%; }

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */ }

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0; }

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */ }

.fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible; }

.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */ }

.fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */ }

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px; }

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap; }

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap; }

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top; }

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */ }

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */ }

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
  /* seperate with a dash, wrapped in nbsp's */ }

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */ }

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize; }

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "="; }

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0; }

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */ }

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px; }

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */ }

.fc-list-view {
  border-width: 1px;
  border-style: solid; }

/* table resets */
.fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */ }

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px; }

.fc-list-table tr:first-child td {
  border-top-width: 0; }

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px; }

.fc-list-heading td {
  font-weight: bold; }

.fc-ltr .fc-list-heading-main {
  float: left; }

.fc-ltr .fc-list-heading-alt {
  float: right; }

.fc-rtl .fc-list-heading-main {
  float: right; }

.fc-rtl .fc-list-heading-alt {
  float: left; }

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */ }

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px; }

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0; }

.fc-rtl .fc-list-item-marker {
  padding-left: 0; }

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit; }

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline; }

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table; }

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee; }





.fc-unthemed .fc-day-grid td:not(.fc-axis) {
  padding: 0.5rem 0.5rem; }
  .fc-unthemed .fc-day-grid td:not(.fc-axis).fc-event-container {
    padding: 0.2rem 0.5rem; }

.fc-unthemed .fc-axis {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.fc-unthemed .fc-scroller .fc-content-col {
  padding: 0.5rem 0.5rem; }

.fc-unthemed th.fc-day-header {
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500; }

.fc-unthemed .fc-list-heading .fc-widget-header {
  padding: 0.75rem 1.25rem; }

.fc-unthemed .fc-list-heading .fc-list-heading-main,
.fc-unthemed .fc-list-heading .fc-list-heading-alt {
  font-size: 1rem;
  font-weight: 400; }

.fc-unthemed .fc-list-heading .fc-list-heading-main {
  font-weight: 500;
  text-transform: uppercase; }

.fc-unthemed .fc-list-item td {
  padding: 0.75rem 1.25rem; }

.fc-unthemed .fc-list-item .fc-event-dot {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%; }

.fc-unthemed .fc-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .fc-unthemed .fc-event .fc-content,
  .fc-unthemed .fc-event-dot .fc-content {
    padding: 0.55rem 0.55rem 0.55rem; }
    .fc-unthemed .fc-event .fc-content:before,
    .fc-unthemed .fc-event-dot .fc-content:before {
      display: none;
      content: " ";
      position: absolute;
      height: 10px;
      width: 10px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      top: 0.7rem;
      left: 0.75rem; }
  .fc-unthemed .fc-event.fc-not-start.fc-end .fc-content,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end .fc-content {
    padding-left: 0.5rem; }
  .fc-unthemed .fc-event .fc-time,
  .fc-unthemed .fc-event-dot .fc-time {
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 600; }
  .fc-unthemed .fc-event .fc-title,
  .fc-unthemed .fc-event-dot .fc-title {
    font-size: 0.85rem;
    font-weight: 400; }

.fc-unthemed .fc-description {
  font-size: 0.85rem;
  margin-top: 0.25rem;
  font-weight: 300; }

.fc-unthemed .fc-list-item-title > a {
  font-size: 1rem;
  font-weight: 500; }

.fc-unthemed a.fc-more {
  font-size: 0.85rem;
  font-weight: 500; }

.fc-unthemed .fc-popover {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px; }
  .fc-unthemed .fc-popover .fc-header {
    padding: 0.75rem 1.25rem;
    -webkit-border-top-left-radius: 6px;
    -moz-border-top-left-radius: 6px;
    -ms-border-top-left-radius: 6px;
    -o-border-top-left-radius: 6px;
    border-top-left-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    -moz-border-top-right-radius: 6px;
    -ms-border-top-right-radius: 6px;
    -o-border-top-right-radius: 6px;
    border-top-right-radius: 6px; }
    .fc-unthemed .fc-popover .fc-header .fc-close {
      margin-top: 0.35rem; }
    .fc-unthemed .fc-popover .fc-header .fc-title {
      font-weight: 400; }
  .fc-unthemed .fc-popover .fc-body .fc-event-container {
    padding: 1.25rem 1.25rem 0.75rem  1.25rem; }
  .fc-unthemed .fc-popover .fc-body .fc-event {
    margin-bottom: 0.5rem;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end {
      -webkit-border-top-right-radius: 0;
      -moz-border-top-right-radius: 0;
      -ms-border-top-right-radius: 0;
      -o-border-top-right-radius: 0;
      border-top-right-radius: 0;
      -webkit-border-bottom-right-radius: 0;
      -moz-border-bottom-right-radius: 0;
      -ms-border-bottom-right-radius: 0;
      -o-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-end {
      -webkit-border-top-left-radius: 0;
      -moz-border-top-left-radius: 0;
      -ms-border-top-left-radius: 0;
      -o-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-bottom-left-radius: 0;
      -ms-border-bottom-left-radius: 0;
      -o-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0; }

.fc-unthemed .fc-toolbar {
  margin-bottom: 1.5rem; }
  .fc-unthemed .fc-toolbar h2 {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0.75rem; }
  .fc-unthemed .fc-toolbar .fc-button {
    outline: none !important;
    height: 2.75rem;
    padding: 0 1.25rem;
    font-size: 1rem; }
    .fc-unthemed .fc-toolbar .fc-button.fc-corner-left {
      -webkit-border-top-left-radius: 4px;
      -moz-border-top-left-radius: 4px;
      -ms-border-top-left-radius: 4px;
      -o-border-top-left-radius: 4px;
      border-top-left-radius: 4px;
      -webkit-border-bottom-left-radius: 4px;
      -moz-border-bottom-left-radius: 4px;
      -ms-border-bottom-left-radius: 4px;
      -o-border-bottom-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .fc-unthemed .fc-toolbar .fc-button.fc-corner-right {
      -webkit-border-top-right-radius: 4px;
      -moz-border-top-right-radius: 4px;
      -ms-border-top-right-radius: 4px;
      -o-border-top-right-radius: 4px;
      border-top-right-radius: 4px;
      -webkit-border-bottom-right-radius: 4px;
      -moz-border-bottom-right-radius: 4px;
      -ms-border-bottom-right-radius: 4px;
      -o-border-bottom-right-radius: 4px;
      border-bottom-right-radius: 4px; }
    .fc-unthemed .fc-toolbar .fc-button .fc-icon {
      font-size: 1.1rem; }
      .fc-unthemed .fc-toolbar .fc-button .fc-icon:after {
        display: none; }
      .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow {
        font-family: "LineAwesome";
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased; }
        .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-left-single-arrow:before {
          content: ""; }
      .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow {
        font-family: "LineAwesome";
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased; }
        .fc-unthemed .fc-toolbar .fc-button .fc-icon.fc-icon-right-single-arrow:before {
          content: ""; }

.fc-unthemed .fc-axis > span {
  font-size: 0.9rem; }

.ui-draggable-handle {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

@media (max-width: 992px) {
  .fc-unthemed .fc-toolbar {
    margin-bottom: 1.5rem; }
    .fc-unthemed .fc-toolbar .fc-left,
    .fc-unthemed .fc-toolbar .fc-right,
    .fc-unthemed .fc-toolbar .fc-center {
      display: block;
      float: none;
      margin-bottom: 1rem;
      text-align: center; }
      .fc-unthemed .fc-toolbar .fc-left h2,
      .fc-unthemed .fc-toolbar .fc-right h2,
      .fc-unthemed .fc-toolbar .fc-center h2 {
        text-align: center;
        float: none; }
      .fc-unthemed .fc-toolbar .fc-left > .fc-button-group,
      .fc-unthemed .fc-toolbar .fc-right > .fc-button-group,
      .fc-unthemed .fc-toolbar .fc-center > .fc-button-group {
        display: inline-block;
        float: none; }
        .fc-unthemed .fc-toolbar .fc-left > .fc-button-group > .fc-button,
        .fc-unthemed .fc-toolbar .fc-right > .fc-button-group > .fc-button,
        .fc-unthemed .fc-toolbar .fc-center > .fc-button-group > .fc-button {
          float: none; }
      .fc-unthemed .fc-toolbar .fc-left > .fc-button,
      .fc-unthemed .fc-toolbar .fc-right > .fc-button,
      .fc-unthemed .fc-toolbar .fc-center > .fc-button {
        float: none; } }

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
  border-color: #ebedf2; }

.fc-unthemed td.fc-past {
  background: #f6f5fc; }

.fc-unthemed .fc-day-grid-event {
  margin: 0; }

.fc-unthemed .fc-event,
.fc-unthemed .fc-event-dot {
  background: #fff;
  border: 1px solid #ebedf2;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.08);
  -moz-box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.08);
  box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.08); }
  .fc-unthemed .fc-event.fc-not-start.fc-not-end,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end {
    border-left: 1px solid #ebedf2; }
  .fc-unthemed .fc-event.fc-start .fc-content:before,
  .fc-unthemed .fc-event-dot.fc-start .fc-content:before {
    background: #adb5ca; }
  .fc-unthemed .fc-event .fc-time,
  .fc-unthemed .fc-event-dot .fc-time {
    color: #333439; }
  .fc-unthemed .fc-event .fc-title,
  .fc-unthemed .fc-event-dot .fc-title {
    color: #3f4047; }

.fc-unthemed .fc-description {
  color: #7b7e8a; }

.fc-unthemed .fc-list-item-title > a[href]:hover {
  color: #716aca;
  text-decoration: none; }

.fc-unthemed .fc-more {
  color: #3f4047; }

.fc-unthemed .fc-event.fc-start.m-fc-event--brand .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--brand .fc-content:before {
  background: #716aca; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-brand, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-brand,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-brand,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-brand {
  background: #716aca; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-brand .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-brand .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-brand .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-brand .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-brand .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-brand .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-brand .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-brand .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-brand .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-brand .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-brand .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-brand .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--metal .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--metal .fc-content:before {
  background: #c4c5d6; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-metal, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-metal,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-metal,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-metal {
  background: #c4c5d6; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-metal .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-metal .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-metal .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-metal .fc-title {
    color: #111111; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-metal .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-metal .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-metal .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-metal .fc-description {
    color: #111111; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-metal .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-metal .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-metal .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-metal .fc-time {
    color: #111111; }

.fc-unthemed .fc-event.fc-start.m-fc-event--light .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--light .fc-content:before {
  background: #ffffff; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-light, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-light,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-light,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-light {
  background: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-light .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-light .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-light .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-light .fc-title {
    color: #282a3c; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-light .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-light .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-light .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-light .fc-description {
    color: #282a3c; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-light .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-light .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-light .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-light .fc-time {
    color: #282a3c; }

.fc-unthemed .fc-event.fc-start.m-fc-event--accent .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--accent .fc-content:before {
  background: #00c5dc; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-accent, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-accent,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-accent,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-accent {
  background: #00c5dc; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-accent .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-accent .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-accent .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-accent .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-accent .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-accent .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-accent .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-accent .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-accent .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-accent .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-accent .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-accent .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--focus .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--focus .fc-content:before {
  background: #9816f4; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-focus, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-focus,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-focus,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-focus {
  background: #9816f4; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-focus .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-focus .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-focus .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-focus .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-focus .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-focus .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-focus .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-focus .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-focus .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-focus .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-focus .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-focus .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--primary .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--primary .fc-content:before {
  background: #5867dd; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-primary, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-primary,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-primary,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-primary {
  background: #5867dd; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-primary .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-primary .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-primary .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-primary .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-primary .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-primary .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-primary .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-primary .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-primary .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-primary .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-primary .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-primary .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--success .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--success .fc-content:before {
  background: #34bfa3; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-success, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-success,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-success,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-success {
  background: #34bfa3; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-success .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-success .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-success .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-success .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-success .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-success .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-success .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-success .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-success .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-success .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-success .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-success .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--info .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--info .fc-content:before {
  background: #36a3f7; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-info, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-info,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-info,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-info {
  background: #36a3f7; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-info .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-info .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-info .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-info .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-info .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-info .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-info .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-info .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-info .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-info .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-info .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-info .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-event.fc-start.m-fc-event--warning .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--warning .fc-content:before {
  background: #ffb822; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-warning, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-warning,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-warning,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-warning {
  background: #ffb822; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-warning .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-warning .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-warning .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-warning .fc-title {
    color: #111111; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-warning .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-warning .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-warning .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-warning .fc-description {
    color: #111111; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-warning .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-warning .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-warning .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-warning .fc-time {
    color: #111111; }

.fc-unthemed .fc-event.fc-start.m-fc-event--danger .fc-content:before,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--danger .fc-content:before {
  background: #f4516c; }

.fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-danger, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-danger,
.fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-danger,
.fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger,
.fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-danger {
  background: #f4516c; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-danger .fc-title, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-title, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-danger .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-danger .fc-title,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-title,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-danger .fc-title {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-danger .fc-description, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-description, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-danger .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-danger .fc-description,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-description,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-danger .fc-description {
    color: #ffffff; }
  .fc-unthemed .fc-event.fc-not-start.fc-end.m-fc-event--solid-danger .fc-time, .fc-unthemed .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-time, .fc-unthemed .fc-event.fc-start.m-fc-event--solid-danger .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-end.m-fc-event--solid-danger .fc-time,
  .fc-unthemed .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-time,
  .fc-unthemed .fc-event-dot.fc-start.m-fc-event--solid-danger .fc-time {
    color: #ffffff; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #ebedf2; }

.fc-unthemed .fc-popover {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  -moz-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  background: #fff; }
  .fc-unthemed .fc-popover .fc-header {
    background: #fff;
    border-bottom: 1px solid #f7f8fa; }
    .fc-unthemed .fc-popover .fc-header .fc-close {
      color: #cfcedb; }
      .fc-unthemed .fc-popover .fc-header .fc-close:hover {
        color: #a4a2bb; }
    .fc-unthemed .fc-popover .fc-header .fc-title {
      color: #575962; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end .fc-content:before {
    background: #adb5ca; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--brand .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--brand .fc-content:before {
    background: #716aca; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand {
    background: #716aca; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-brand .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--metal .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--metal .fc-content:before {
    background: #c4c5d6; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal {
    background: #c4c5d6; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-title {
      color: #111111; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-description {
      color: #111111; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-metal .fc-time {
      color: #111111; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--light .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--light .fc-content:before {
    background: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light {
    background: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-title {
      color: #282a3c; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-description {
      color: #282a3c; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-light .fc-time {
      color: #282a3c; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--accent .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--accent .fc-content:before {
    background: #00c5dc; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent {
    background: #00c5dc; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-accent .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--focus .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--focus .fc-content:before {
    background: #9816f4; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus {
    background: #9816f4; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-focus .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--primary .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--primary .fc-content:before {
    background: #5867dd; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary {
    background: #5867dd; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-primary .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--success .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--success .fc-content:before {
    background: #34bfa3; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success {
    background: #34bfa3; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-success .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--info .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--info .fc-content:before {
    background: #36a3f7; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info {
    background: #36a3f7; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-info .fc-time {
      color: #ffffff; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--warning .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--warning .fc-content:before {
    background: #ffb822; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning {
    background: #ffb822; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-title {
      color: #111111; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-description {
      color: #111111; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-warning .fc-time {
      color: #111111; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--danger .fc-content:before,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--danger .fc-content:before {
    background: #f4516c; }
  .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger,
  .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger {
    background: #f4516c; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-title,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-title {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-description,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-description {
      color: #ffffff; }
    .fc-unthemed .fc-popover .fc-body .fc-event.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-time,
    .fc-unthemed .fc-popover .fc-body .fc-event-dot.fc-not-start.fc-not-end.m-fc-event--solid-danger .fc-time {
      color: #ffffff; }

.fc-unthemed th.fc-day-header > a,
.fc-unthemed th.fc-day-header > span {
  color: #898b96; }

.fc-unthemed .fc-list-heading td {
  background: #f4f5f8;
  border-color: #f4f5f8; }

.fc-unthemed .fc-list-heading .fc-list-heading-main {
  color: #575962; }

.fc-unthemed .fc-list-heading .fc-list-heading-alt {
  color: #7b7e8a; }

.fc-unthemed .fc-list-item:hover td {
  background: transparent !important; }

.fc-unthemed .fc-list-item .fc-event-dot {
  background: #adb5ca;
  border-color: #adb5ca; }

.fc-unthemed .fc-list-item.m-fc-event--solid-brand .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--brand .fc-event-dot {
  background: #716aca;
  border-color: #716aca; }

.fc-unthemed .fc-list-item.m-fc-event--solid-metal .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--metal .fc-event-dot {
  background: #c4c5d6;
  border-color: #c4c5d6; }

.fc-unthemed .fc-list-item.m-fc-event--solid-light .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--light .fc-event-dot {
  background: #ffffff;
  border-color: #ffffff; }

.fc-unthemed .fc-list-item.m-fc-event--solid-accent .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--accent .fc-event-dot {
  background: #00c5dc;
  border-color: #00c5dc; }

.fc-unthemed .fc-list-item.m-fc-event--solid-focus .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--focus .fc-event-dot {
  background: #9816f4;
  border-color: #9816f4; }

.fc-unthemed .fc-list-item.m-fc-event--solid-primary .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--primary .fc-event-dot {
  background: #5867dd;
  border-color: #5867dd; }

.fc-unthemed .fc-list-item.m-fc-event--solid-success .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--success .fc-event-dot {
  background: #34bfa3;
  border-color: #34bfa3; }

.fc-unthemed .fc-list-item.m-fc-event--solid-info .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--info .fc-event-dot {
  background: #36a3f7;
  border-color: #36a3f7; }

.fc-unthemed .fc-list-item.m-fc-event--solid-warning .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--warning .fc-event-dot {
  background: #ffb822;
  border-color: #ffb822; }

.fc-unthemed .fc-list-item.m-fc-event--solid-danger .fc-event-dot, .fc-unthemed .fc-list-item.m-fc-event--danger .fc-event-dot {
  background: #f4516c;
  border-color: #f4516c; }

.fc-unthemed .fc-toolbar .fc-button {
  background: #f4f5f8;
  border: 0;
  text-shadow: none !important; }
  .fc-unthemed .fc-toolbar .fc-button:hover {
    border: 0;
    background: #e2e5ec; }
  .fc-unthemed .fc-toolbar .fc-button:focus, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button.fc-state-active {
    border: 0;
    background: #716aca;
    color: #ffffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none; }
    .fc-unthemed .fc-toolbar .fc-button:focus .fc-icon, .fc-unthemed .fc-toolbar .fc-button:active .fc-icon, .fc-unthemed .fc-toolbar .fc-button.fc-state-active .fc-icon {
      color: #ffffff; }
  .fc-unthemed .fc-toolbar .fc-button.fc-state-disabled {
    background: #f7f8fa;
    color: #afb2c1; }

.fc-unthemed tr:first-child>td>.fc-day-grid-event,
.fc-unthemed .fc-event.fc-start.m-fc-event--solid-warning {
  padding: 0;
}

.fc-time-grid.fc-unselectable table {
    display:none
}


.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-menu {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 12rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
  border-radius: 4px; /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
  border-radius: 4px !important; /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ui-select-bootstrap > .ui-select-match > .btn{
  /* Instead of center because of .btn */
  text-align: left !important;
}

.ui-select-bootstrap > .ui-select-match > .caret {
  position: absolute;
  top: 45%;
  right: 15px;
}

.ui-disabled {
    background-color: #eceeef ;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.6;
    top: 0;
    left: 0;
    cursor: not-allowed;
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-bootstrap > .ui-select-choices {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  margin-top: -1px;
}

body > .ui-select-bootstrap.open {
  z-index: 1000; /* Standard Bootstrap dropdown z-index */
}

.ui-select-multiple.ui-select-bootstrap {
  height: auto;
  padding: 3px 3px 0 3px;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background-color: transparent !important; /* To prevent double background when disabled */
  border: none;
  outline: none;
  height: 1.666666em;
  margin-bottom: 3px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  font-size: 1.6em;
  line-height: 0.75;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  outline: 0;
  margin: 0 3px 3px 0;
}

.ui-select-multiple .ui-select-match-item {
  position: relative;
}

.ui-select-multiple .ui-select-match-item.dropping-before:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  margin-right: 2px;
  border-left: 1px solid #428bca;
}

.ui-select-multiple .ui-select-match-item.dropping-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  margin-left: 2px;
  border-right: 1px solid #428bca;
}

.ui-select-bootstrap .ui-select-choices-row>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row>a:hover, .ui-select-bootstrap .ui-select-choices-row>a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.ui-select-bootstrap .ui-select-choices-row.active>a {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #428bca;
}

.ui-select-bootstrap .ui-select-choices-row.disabled>a,
.ui-select-bootstrap .ui-select-choices-row.active.disabled>a {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
    display: none !important;
}

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.btn.ui-select-match {
    border-color: #D44950;
}

/* Handle up direction Bootstrap */
.ui-select-container[theme="bootstrap"].direction-up .ui-select-dropdown {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.open>.dropdown-menu {
  display: block
}

.ui-select-container {
  position: relative;
  display: block
}

.hc-multiselect .selected-list .c-btn {
  padding: .5rem 1.25rem!important;
  box-shadow: none!important;
  line-height: normal;
  border: 1px solid #ebedf2!important
}

.hc-multiselect .selected-list .c-list .c-token {
  line-height: normal
}

.hc-multiselect .selected-list .c-list .c-token .c-label {
  font-size: 12px;
  text-transform: lowercase
}

/* import svg sprite */
.hc-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 16px 16px;
  background-image: url('/assets/images/sprite.svg');
}

.m--text-line-through {
  text-decoration: line-through;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.container--top {
  margin-top: 5rem;
}

.hc-container {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.hc-container__block {
  font-size: 14px;
  color: #333333;
  padding: 25px;
  border-bottom: 1px solid #E0E0E0;
}

.hc-container__block:last-child {
  border-bottom: 0;
}

.hc-container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.hc-container__title {
  color: #0788FF;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 10px;
}

.hc-container__title--reset {
  margin: 0;
}

.hc-container__404 {
  padding: 1rem 2rem;
}

.hc-mydatepicker .mydp .selection {
  background: #F2F3F8;
  border-radius: 5px;
  padding: 9px 15px;
  min-height: 40px;
}

.hc-mydatepicker .mydp {
  border-color: #F2F3F8;
}

.hc-mydatepicker .mydp .btnpicker {
  background-color: #F2F3F8;
}

.hc-mydatepicker .mydp .btnclear {
  display: none;
}

.mydp .btnpicker {
}

.hc-button--tag {
  color: #333;
  padding: 2px 7px;
  font-size: 14px;
  color: #0788FF;
  border-radius: 5px;
}

.fc-event {
  padding: 5px 0;
}

hx-date-range-select button.btn {
  line-height: 1;
}
